import React from 'react';
import { Container } from 'theme-ui';
import RevealAnimationWrapper from '../components/atoms/RevealAnimationWrapper';
import CmsContent from '../components/molecules/CmsContent';
import { CmsContentType } from '../components/molecules/CmsContent/enums';

const PrivacyPolicyPage: React.FC = () => (
  <Container variant="layout.containerNarrow" pt={['2.25rem', '5rem', '10rem']}>
    <RevealAnimationWrapper>
      <CmsContent type={CmsContentType.simple}>
        <h1>Cookie policy</h1>
        <h2>This website uses cookies.</h2>
        <p>
          We use cookies to personalise content, to provide social media
          features and to analyse our traffic. You consent to our cookies if you
          continue to use our website.
        </p>
        <p>
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your computer if you agree. Cookies
          contain information that is transferred to your computer’s hard drive.
        </p>
        <h2>We use the following cookies:</h2>
        <p>
          <strong>Strictly necessary cookies.</strong>
          These are cookies that are required for the operation of our website.
        </p>
        <p>
          <strong>Analytical/performance cookies.</strong>
          They allow us to recognise and count the number of visitors and to see
          how visitors move around our website when they are using it. This
          helps us to improve the way our website works, for example, by
          ensuring that users are finding what they are looking for easily.
        </p>
        <p>
          <strong>Targeting cookies. </strong>
          These cookies record your visit to our website, the pages you have
          visited and the links you have followed. We will use this information
          to make our website more relevant to your interests.
        </p>
        <p>
          You can find more information about the individual cookies we use and
          the purposes for which we use below:
        </p>
        <p>
          <strong>Cookie:</strong> _ga
          <br />
          <strong>Name:</strong> Google
          <br />
          <strong>Purpose:</strong> This cookie registers a unique ID that is
          used to generate statistical data on how the visitor uses the Luminous
          website.
        </p>
        <p>
          <strong>Cookie:</strong> _hstc
          <br />
          <strong>Name:</strong> HubSpot
          <br />
          <strong>Purpose:</strong> The main cookie for tracking visitors. It
          contains the domain, utk, initial timestamp (first visit), last
          timestamp (last visit), current timestamp (this visit), and session
          number (increments for each subsequent session).
        </p>
        <p>
          <strong>Cookie:</strong> hubspotuk
          <br />
          <strong>Name:</strong> HubSpot
          <br />
          <strong>Purpose:</strong> This cookie is used to keep track of a
          visitor’s identity. This cookie is passed to HubSpot on form
          submission and used when de-duplicating contacts.
        </p>
        <p>
          <strong>Cookie:</strong> _hssc
          <br />
          <strong>Name:</strong> HubSpot
          <br />
          <strong>Purpose:</strong> This cookie keeps track of sessions. This is
          used to determine if HubSpot should increment the session number and
          timestamps in the __hstc cookie. It contains the domain, viewCount
          (increments each pageView in a session), and session start timestamp.
        </p>
        <p>
          <strong>Cookie:</strong> _hssrc
          <br />
          <strong>Name:</strong> HubSpot
          <br />
          <strong>Purpose:</strong> Whenever HubSpot changes the session cookie,
          this cookie is also set to determine if the visitor has restarted
          their browser. If this cookie does not exist when HubSpot manages
          cookies, it is considered a new session.
        </p>
        <p>
          You block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies) you may not be able to access all or parts of our
          site.
        </p>
      </CmsContent>
    </RevealAnimationWrapper>
  </Container>
);

export default PrivacyPolicyPage;
